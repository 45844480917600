import mnml from '@dryan-llc/mnml.js';

(() => {
  if (window.customElements.get('pr-cg-stats')) return;

  class PrideraiserCampaignGroupStats extends HTMLElement {
    get uid(): string | null {
      return this.getAttribute('group');
    }
    set uid(value: string | null) {
      if (value) {
        this.setAttribute('group', value);
      } else {
        this.removeAttribute('group');
      }
    }

    getStats() {
      fetch(`${document.body.dataset.apiBase}groups/${this.uid}/`)
        .then((response) => response.json())
        .then((data) => {
          this.render(data.stats || []);
        })
        .catch((error) => console.error(error));
    }

    connectedCallback() {
      this.getStats();
    }

    render(data: [string, string][]) {
      const stats: { [key: string]: string[] } = {};
      data.forEach((stat) => {
        if (!stats[stat[0]]) {
          stats[stat[0]] = [];
        }
        stats[stat[0]].push(stat[1]);
        stats[stat[0]].sort();
      });
      const mobileCard = mnml.html`<pr-card variant="stats stats-mobile">
          <pr-card-body>
            <ul class="stats-list"></ul>
          </pr-card-body>
        </pr-card>`.querySelector('pr-card') as HTMLElement;
      const mobileList = mobileCard.querySelector('ul') as HTMLUListElement;
      const desktopGrid =
        mnml.html`<div class="card-grid stats-grid stats-grid--${
          Object.keys(stats).length
        }"></div>`.querySelector('div') as HTMLElement;
      Object.entries(stats).forEach(([stat, values]) => {
        mobileList.append(mnml.html`
          <li class="stats-list__item">
            <span class="stats-list__item__label">${stat}</span>
            <span class="stats-list__item__value">${values.join(
              '<br />'
            )}</span>
          </li>
        `);
        desktopGrid.append(mnml.html`
          <pr-card class="stats">
            <pr-card-body>
              <p class="title">${stat}</p>
              <p class="big-num${
                values.length > 1 ? ' big-num--multiple' : ''
              }">${values.join('<br />')}</p>
            </pr-card-body>
          </pr-card>
        `);
      });
      this.replaceChildren(mobileCard, desktopGrid);
    }
  }

  window.customElements.define('pr-cg-stats', PrideraiserCampaignGroupStats);
})();
